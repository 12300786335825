<template>
  <modal-wrapper :id="id" hide-footer size="md">
    <template #title>
      Carica nuovo documento
    </template>
    <template #body>
      <b-form @submit.prevent="submitForm" class="form-inputs--mobile--lg">
        <div>
          <b-form-group label="Tipologia documento">
            <v-select
              id="docType"
              :options="docTypesTrans"
              v-model="$v.formDocUpload.docType.$model"
              label="value"
              :reduce="item => item.key"
              v-on:option:selected="clearOther"
            />
          </b-form-group>
        </div>
        <div v-if="isOtherType">
          <field-text
            fieldId="docNameOther"
            placeholder="Nome del documento"
            title="Nome del documento"
            :state="validateState($v.formDocUpload.docNameOther)"
            v-model="$v.formDocUpload.docNameOther.$model"
            :errorMessage="decodeError($v.formDocUpload, 'docNameOther')"
          />
        </div>
        <vue-dropzone
          v-if="isUploadAvailable"
          ref="imgDropZone"
          :id="dropZoneId"
          :options="dropzoneOptions"
          v-on:vdropzone-sending="sendingEvent"
          @vdropzone-success="afterSuccess"
          @vdropzone-complete="afterComplete"
        ></vue-dropzone>
      </b-form>
    </template>
  </modal-wrapper>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import { required } from 'vuelidate/lib/validators';
import lodash from 'lodash';
import TokenService from '@/services/token.service';
import {
  isPresent, isNotEmpty, decodeFormFieldError, validateFormFieldState,
} from '@/utils/validators';
import {
  createUriUpload,
  decodeDocument,
  extensionAttach,
  mimeTypeAttach,
} from '@/utils/documents';

const ModalWrapper = () => import('@/components/helpers/ModalWrapper.vue');
const FieldText = () => import('@/components/fields/FieldText.vue');

const checkOtherDocument = (value, form) => {
  if (form.docType === 'OTHER') {
    return isPresent(value);
  }
  return true;
};

export default {
  name: 'ModalAddDocument',
  props: {
    id: String,
    imageType: String,
    entityType: String,
    entityId: String,
    documentEntityType: String,
    onSuccess: Function,
  },
  components: {
    ModalWrapper,
    vueDropzone: vue2Dropzone,
    FieldText,
  },
  data() {
    return {
      formDocUpload: {
        docType: '',
        docNameOther: '',
      },
      submitted: false,
      showCompany: false,
      documentToUpload: {
        document: null,
        documentName: null,
      },
      editMode: false,
      clientValidation: {
        formDocUpload: {
          docType: {
            required,
          },
          docNameOther: {
            checkOtherDocument,
          },
        },
      },
    };
  },
  computed: {
    dropZoneId() {
      // console.log('checking dropzone id', this.id);
      return `${this.id}_dropzone`;
    },
    dropzoneOptions() {
      return {
        url: createUriUpload(null, this.entityType, this.entityId),
        thumbnailWidth: 350,
        thumbnailHeight: 100,
        addRemoveLinks: false,
        acceptedFiles: mimeTypeAttach.join(),
        headers: TokenService.authHeader(),
        dictDefaultMessage: `<p class='text-default'>
                                <font-awesome-icon icon="cloud-upload-alt"></font-awesome-icon>
                                Trascina un documento o fai click in quest'area
                             </p>
          <p class="form-text">File accettati: ${extensionAttach.join()}</p>
          `,
      };
    },
    // isDoc() {
    //   return isNotEmpty(this.document);
    // },
    rules() {
      // When the serverValidation property is set this computed property
      // fires and merges the client and server validation
      return lodash.merge({}, this.clientValidation);
    },
    isOtherType() {
      return this.formDocUpload.docType === 'OTHER';
    },
    docTypesTrans() {
      const docTypes = this.$store.getters['documents/getDocumentTypeList'](this.documentEntityType);
      if (isPresent(docTypes)) {
        return docTypes.map((item) => {
          let value;
          if (item === 'OTHER') {
            value = 'Altro';
          } else {
            value = decodeDocument(
              {
                documentInfo: {
                  documentEntityType: this.documentEntityType,
                  documentType: item,
                },
              },
              this.$store.getters['documents/getDocumentTranslation'],
            );
          }
          return {
            key: item,
            value,
          };
        });
      }
      return [];
    },
    isUploadAvailable() {
      if (this.isOtherType) {
        return isPresent(this.formDocUpload.docType) && isPresent(this.formDocUpload.docNameOther);
      }
      return isPresent(this.formDocUpload.docType);
    },
  },
  validations() {
    return this.rules;
  },
  methods: {
    //         v-on:vdropzone-sending="sendingEvent"
    sendingEvent(file, xhr, formData) {
      formData.append('documentEntityType', this.documentEntityType);
      formData.append('documentType', this.formDocUpload.docType);
      if (this.isOtherType) {
        formData.append('documentTitle', this.formDocUpload.docNameOther);
      }
    },
    async afterSuccess(file, response) {
      console.log('response', response);
      if (isNotEmpty(this.onSuccess)) {
        this.onSuccess(response.data);
      }
      this.formDocUpload.docNameOther = '';
      this.formDocUpload.docType = '';
      this.$bvModal.hide(this.id);
    },
    async afterComplete() {
      // this.$bvModal.hide(this.id);
      console.log('afterComplete');
    },
    validateState: validateFormFieldState,
    decodeError: decodeFormFieldError,
    submitForm() {
      console.log('submit');
    },
    updateDocType(val) {
      console.log('val', val);
    },
    clearOther() {
      this.formDocUpload.docNameOther = '';
    },
  },
};
</script>

<style scoped>

</style>
